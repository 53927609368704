body.asj-bs {
  &.layout-reservations {
    &.reservations-meeting_reservations {
      .meeting-campaigns-list {
        .meeting-campaign {
          .hero-image {
            max-height: 28vh;
            object-fit: cover;
          }
          .participants {
            img {
              height: 8rem;
            }
          }
        }
      }

      &.reservations-meeting_reservations-new {
        .campaign-content {
          margin-bottom: 400px;

          .hero-images {
            img {
              max-height: 40vh;
              object-fit: cover;
            }
          }

          .participants {
            .participants-list {
              list-style: none;

              .participant {
                figure {
                  img {
                    min-width: 128px;
                    max-width: 128px;
                    height: auto;
                  }
                }
                .date-picker-container {
                  min-width: 340px;
                  max-width: 400px;
                }
                .extra-content {
                  max-width: 33vw;
                  img {
                    max-height: 25vh;
                    object-fit: cover;
                    width: 100%;
                    height: auto;
                  }
                }
              }
            }
          }
        }

        .reservation-form {
          .choices {
            counter-reset: position;
            .list-group-item {
              counter-increment: position;

              .sort-handle {
                width: 2rem;
              }
              .rank {
                width: 2rem;
                span::after {
                  content: counter(position) ".";
                }
              }
              .participant-name-and-date {
                flex-basis: 19em;
                flex-shrink: 0;
              }
              .participant-name {
                flex-basis: 8em;
                flex-shrink: 0;
              }
            }
          }

          @include media-breakpoint-down(md) {
            .cant-reserve {
              .d-flex {
                div {
                  &:first-of-type,
                  &:last-of-type {
                    flex-basis: 100%;
                  }
                }
              }
            }
          }

          @include media-breakpoint-down(sm) {
            h2 {
              font-size: 1rem;
            }
            form {
              font-size: 0.86rem;
              .form-select, .form-input, .input-group-text {
                font-size: 0.86rem;
              }
              .rank {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
