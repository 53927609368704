@charset 'utf-8';

$meeting_campaign_range: #d1e7dd;

.meeting-campaign-range {
  background-color: $meeting_campaign_range;
}

.asj-debug {
  color: var(--bs-tertiary-color) !important;
}
