@charset 'utf-8';

// Fonts ///////////////////////////////////////////////////////////////
// For Bootstrap
$font-family-sans-serif: 'poynter-gothic-text', 'ryo-gothic-plusn', '游ゴシック', YuGothic, 'メイリオ', Meiryo, 'ヒラギノ角ゴPro W3', 'Hiragino Kaku Gothic Pro', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


// Colors //////////////////////////////////////////////////////////////
// For Bootstrap
$blue: #154489;
$secondary: #75736d;
$red: #c01921;
$green:  #89A346;

// For Mobiscroll 5
$mbsc-ios-accent: #154489;
$mbsc-mobiscroll-accent: #154489;


// Spacing /////////////////////////////////////////////////////////////
$enable-negative-margins: true;
$navbar-nav-link-padding-x: 1rem;


// Sizes ///////////////////////////////////////////////////////////////
$header-logo-size: 2rem;


// Classes /////////////////////////////////////////////////////////////
.asj-error {
  font-weight: bold;
  color: $red;
}

.ui-help {
  font-weight: 300;
  font-style: italic;
  font-size: small;
}
